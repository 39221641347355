@font-face {
  font-family: 'Galano';
  font-weight: 100;
  src: local('Galano'), url(./assets/fonts/GalanoGrotesqueExtraLight.otf) format('opentype');
}
@font-face {
  font-family: 'Galano';
  font-weight: 200;
  src: local('Galano'), url(./assets/fonts/GalanoGrotesqueLight.otf) format('opentype');
}
@font-face {
  font-family: 'Galano';
  font-weight: 300;
  src: local('Galano'), url(./assets/fonts/GalanoGrotesqueRegular.otf) format('opentype');
}
@font-face {
  font-family: 'Galano';
  font-weight: 400;
  src: local('Galano'), url(./assets/fonts/GalanoGrotesqueMedium.otf) format('opentype');
}
@font-face {
  font-family: 'Galano';
  font-weight: 500;
  src: local('Galano'), url(./assets/fonts/GalanoGrotesqueSemiBold.otf) format('opentype');
}
@font-face {
  font-family: 'Galano';
  font-weight: 600;
  src: local('Galano'), url(./assets/fonts/GalanoGrotesqueSemiBold.otf) format('opentype');
}
@font-face {
  font-family: 'Galano';
  font-weight: 700;
  src: local('Galano'), url(./assets/fonts/GalanoGrotesqueBold.otf) format('opentype');
}
@font-face {
  font-family: 'Galano';
  font-weight: 700;
  src: local('Galano'), url(./assets/fonts/GalanoGrotesqueExtraBold.otf) format('opentype');
}

@font-face {
  font-family: 'Galano';
  font-weight: 800;
  src: local('Galano'), url(./assets/fonts/GalanoGrotesqueHeavy.otf) format('opentype');
}

.table {
  font-family: 'Galano';
  font-weight: 300;
  font-size: 14px;
  color: #000000;
  /* line-height: 1.5;
  letter-spacing: 0.5px; */
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  padding: 0px;
}

.table th {
  font-weight: 400;
  font-size: 12px;
  color: #555555;
  line-height: 1;
  /* letter-spacing: 0.5px; */
  /* text-align: left; */
  font-family: 'Galano';
  background-color: #EDEDED;
  padding: 0px;
  /* padding-left: 12px; */
}

.table td {
  padding-left: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.table body {
  padding-left: 6px;
}